import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {ConceptSelection, SessionObject} from '@twpub/core/models';
import {ClientConfigurationService} from '@twpub/core/services';

@Component({
  selector: 'pub-concept-view-dialog',
  templateUrl: './concept-view-dialog.component.html',
  styleUrls: ['./concept-view-dialog.component.scss']
})
export class ConceptViewDialogComponent {
  sessionObj: SessionObject;
  configId?: number;
  conceptId?: number;
  termId?: number;

  constructor(private logger: NGXLogger, private dialog: MatDialog, private configService: ClientConfigurationService,
              public dialogRef: MatDialogRef<ConceptViewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { sessionObj: SessionObject, configId: number }) {
    this.sessionObj = data.sessionObj;
    this.configService.getDefaultConfiguration(data.configId).subscribe((config) => {
      this.configId = config.id;
    });
    this.conceptId = this.sessionObj.conceptId;
    this.termId = this.sessionObj.termId;
  }

  selectConcept(selection: ConceptSelection) {
    this.sessionObj.conceptId = selection.conceptId;
    this.sessionObj.termId = selection.termId;
    this.conceptId = selection.conceptId;
    this.termId = selection.termId;
  }
}
