import {Component, Injector} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ResultListProp, SearchResultConfig} from '@twpub/core/constants';
import {
  ConceptService,
  DictionaryService,
  NavigationService,
  SearchService,
  SharedStateService
} from '@twpub/core/services';
import {
  ConceptSelection,
  Language,
  PaginationData,
  SearchCriteria,
  SessionObject,
  TermSearchResult
} from '@twpub/core/models';
import {fromPageEvent} from '@twpub/core/utils';

@Component({
  template: ''
})
export abstract class BaseResultListComponent extends BaseConfigurableComponent {
  protected searchService: SearchService;
  protected dictService: DictionaryService
  protected conceptService: ConceptService;
  protected naviService: NavigationService;
  protected sharedStateService: SharedStateService;
  resultPaginator!: PaginationData;
  results?: TermSearchResult[];

  dataSource: MatTableDataSource<TermSearchResult> = new MatTableDataSource();
  isSearching = false;
  isWaiting = false;
  searchConfig!: SearchResultConfig
  includeSynonyms = false; // endast AF
  fieldNames: string[] = []
  protected languages: Language[] = [];
  protected dictKey: string = '';

  private dictId?: number;
  protected sourceLangCodes: string[] = [];
  private targetLangCodes: string[] = [];
  /**
   * Explicitly casts the type of the result object to avoid "Unresolved variable" errors in template.
   * @param res - TermSearchResult object from MatTableDataSource
   */
  toRes = (res: any): TermSearchResult => res as TermSearchResult;


  hasMultipleSourceLangs = () => this.sourceLangCodes.length !== 1;

  isSourceLang = (code: string) => this.sourceLangCodes.includes(code);

  protected constructor(private injectorObj: Injector) {
    super();
    this.naviService = injectorObj.get(NavigationService);
    this.searchService = injectorObj.get(SearchService);
    this.dictService = injectorObj.get(DictionaryService);
    this.conceptService = injectorObj.get(ConceptService);
    this.sharedStateService = injectorObj.get(SharedStateService);
  }

  protected performSearch(pData?: PaginationData, clear: boolean = false, callbackFn?: (resultIndex?: number) => void) {
    const sourceLangs = this.sharedStateService.getSourceLangs();
    const dictId = this.sharedStateService.getDictionaryId();
    if (this.shouldPerformSearch() && sourceLangs && dictId) {
      const searchCriteria = new SearchCriteria(this.sharedStateService._sessionObj);
      this.searchService.search(searchCriteria, this.searchConfig, pData).subscribe({
        next: (pagedResult) => {
          if (clear || this.results === undefined) {
            this.results = [];
          }
          this.results.push(...pagedResult.results);
          this.dataSource.data = this.results; // todo move to child class. Only relevant for mat-table
          this.logger.debug('BaseResultListComponent.next:', {results: this.results})
          this.sharedStateService.setPaginationData(fromPageEvent({
            pageSize: pagedResult.pageSize,
            pageIndex: pData?.pageIndex || 0,
            previousPageIndex: pData?.previousPageIndex || 0,
            length: pagedResult.total
          }));
          this.isSearching = true;
          this.resultPaginator = this.sharedStateService.getPaginationData();
          this.sharedStateService.setPaginationData(this.resultPaginator);
          if (callbackFn) {
            callbackFn(this.sharedStateService.getResultIndex());
          }
          this.isWaiting = false
        }
      });
    } else {
      this.isWaiting = false;
    }
  }

  private shouldPerformSearch() {
    const initialSearch = this.config.getBoolean(ResultListProp.InitialSearch);
    const searchTerm = this.sharedStateService.getSearchTerm();
    return initialSearch || searchTerm?.length;
  }

  protected onInit() {
    this.isWaiting = true;
    const includeSynonyms = this.config.getBoolean(ResultListProp.IncludeSynonyms);
    const initialSearch = this.config.getBoolean(ResultListProp.InitialSearch);
    const fieldNames = this.config.getString(ResultListProp.Fields);
    this.searchConfig = {
      [ResultListProp.IncludeSynonyms]: includeSynonyms,
      [ResultListProp.InitialSearch]: initialSearch,
      [ResultListProp.Fields]: fieldNames
    }

    this.includeSynonyms = includeSynonyms;
    this.fieldNames = fieldNames?.split(';') || [];
  }

  protected fetchLangNames(callback?: (langs: Language[]) => void) {
    this.dictId = this.sharedStateService.getDictionaryId();
    if (this.dictId) {
      this.sourceLangCodes = this.sharedStateService.getSourceLangs();
      this.targetLangCodes = this.sharedStateService.getTargetLangs();
      this.dictService.getLanguages(this.dictId).subscribe({
        next: (langs) => {
          this.languages = langs;
          callback?.(langs);
        }
      });
      this.dictService.getDictionary(this.dictId).subscribe({
        next: (dict) => {
          this.dictKey = dict.key;
        }
      });
    }
  }

  needToFetchLangNames() {
    return this.sharedStateService.getDictionaryId() !== this.dictId ||
      this.sharedStateService.getSourceLangs() !== this.sourceLangCodes ||
      this.sharedStateService.getTargetLangs() !== this.targetLangCodes;
  }

  override doUpdate(): void {
    super.doUpdate();
    this.isWaiting = true;
    if (this.needToFetchLangNames()) {
      this.fetchLangNames();
    }
    this.performSearch(this.sharedStateService.getPaginationData(), true);
  }

  hasNoResults() {
    return !this.dataSource.data?.length && this.sharedStateService.getSearchTerm()?.length
  }

  resultClick(res: TermSearchResult, index?: number, skipUrl?: boolean) {
    const conceptId = res.term.conceptId;
    const termId = res.term.id;
    this.logger.debug('BaseResultListComponent.resultClick:----------------------', {conceptId, termId})

    if (!skipUrl) {
      const url = this.naviService.createUrl(this.sharedStateService.getDictionaryId(), conceptId, termId);
      this.naviService.replaceState(url);
    }
    this.sharedStateService.selectConcept({conceptId, termId, index});
  }

  selectConceptFromView(selection: ConceptSelection) {
    this.logger.trace('BaseResultListComponent.selectConceptFromView: ', {selection})
    this.sharedStateService.selectConcept(selection);
  }

  getSessionObj(): SessionObject {
    return this.sharedStateService._sessionObj;
  }
}
