<pub-debug-panel [sessionObj]=sessionObj></pub-debug-panel>
<div id="page-container" *ngIf="isConfigurationOk && clientConfig">
  <div id="page-content" class="twpub-config-{{clientConfig.id}}">
    <!--start header-->
    <pub-header-wrapper></pub-header-wrapper>
    <!--end header-->

    <!--start search fields-->
    <digi-layout-block afVerticalPadding="true">
      <pub-preamble-wrapper></pub-preamble-wrapper>
    </digi-layout-block>
    <pub-result-view-wrapper></pub-result-view-wrapper>
  </div>
  <!--start footer-->
  <pub-footer-wrapper></pub-footer-wrapper>
  <!--end footer-->
</div>

