import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {FieldProps, Language, LanguageSelector} from '@twpub/core/models';
import {ComponentCategory, ControlType} from '@twpub/core/enums';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {MatButtonToggleGroup} from '@angular/material/button-toggle';

@Component({
  selector: 'pub-language-radio',
  templateUrl: './language-radio.component.html',
  styleUrls: ['./language-radio.component.scss']
})
export class LanguageRadioComponent extends BaseConfigurableComponent implements LanguageSelector {
  static readonly compName = 'LanguageRadioComponent';
  static readonly DEFAULT_VALUE = 'defaultValue';

  @Input() currentLanguages?: string[];
  @Input() langs: Language[] = [];

  @Output() selectLanguages: EventEmitter<string[]> = new EventEmitter<string[]>()

  @ViewChild(MatButtonToggleGroup) toggleGroup!: MatButtonToggleGroup;

  readonly MAX_BUTTONS = 5;

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD,
      {key: LanguageRadioComponent.DEFAULT_VALUE, label: 'Default language code', controlType: ControlType.Textbox}
    ];

    constructor() {
      super(LanguageRadioComponent, LanguageRadioComponent.compName, 'Radio Buttons', [ComponentCategory.SourceLanguageSelector, ComponentCategory.TargetLanguageSelector]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<LanguageSelector>(LanguageRadioComponent, this.name, initialConfig);
    }
  }

  private filterExisting = (langs: string[] | undefined) => {
    if (!langs?.length) return undefined;
    const validLanguages = langs.filter(code =>
      this.langs?.some(l => l.code === code)
    );
    return validLanguages.length < langs.length ? validLanguages : langs;
  };

  override doUpdate() {
    super.doUpdate();
    let actualLangs = this.filterExisting(this.currentLanguages);
    if (!actualLangs?.length) {
      const defaultLangs = this.config?.getStringArray(LanguageRadioComponent.DEFAULT_VALUE, undefined);
      actualLangs = this.filterExisting(defaultLangs?.slice(0, 1) || []);
    }
    if (!actualLangs?.length) {
      actualLangs = this.langs?.[0] ? [this.langs[0].code] : [];
    }
    if (actualLangs !== this.currentLanguages) {
      this.currentLanguages = actualLangs;
      this.selectLanguages.emit(actualLangs);
    }
  }

  isLanguageSelected(langCode: string) {
    return this.currentLanguages?.includes(langCode);
  }

  onSelect(langCode: string) {
    this.currentLanguages = [langCode];
    if (this.toggleGroup) {
      this.toggleGroup.value = langCode;
    }
    this.selectLanguages.emit([langCode]);
  }
}
