<pub-debug-panel [sessionObj]=sessionObj></pub-debug-panel>
<div id="page-container" *ngIf="isConfigurationOk && clientConfig">
  <div id="page-content" class="twpub-config-{{clientConfig.id}}">
    <!--start header-->
    <pub-header-wrapper></pub-header-wrapper>
    <!--end header-->
    <main>
      <!--start search fields-->
      <digi-layout-block afVerticalPadding="true">
        <pub-preamble-wrapper></pub-preamble-wrapper>
        <div class="block-container">
          <div class="dictionary-block">
            <pub-dictionary-wrapper></pub-dictionary-wrapper>
          </div>
          <div class="search-block">
            <pub-search-field-wrapper></pub-search-field-wrapper>
          </div>

          <div class="language-block">
            <pub-source-language-wrapper></pub-source-language-wrapper>
            <pub-target-language-wrapper></pub-target-language-wrapper>
          </div>
        </div>
        <pub-advanced-search-wrapper></pub-advanced-search-wrapper>
      </digi-layout-block>
      <!--end search fields-->

      <!--start result list-->
      <pub-result-list-wrapper></pub-result-list-wrapper>
      <!--end result list -->
    </main>
  </div>
  <!--start footer-->
  <pub-footer-wrapper></pub-footer-wrapper>
  <!-- end footer-->
</div>
