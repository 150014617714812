<ng-container *ngIf="visible">
  <mat-form-field *ngIf="domains && domains.length>1" [ngClass]="cssClassName">
    <mat-label *ngIf="label?.length">{{ label }}</mat-label>
    <mat-select #select [value]="getSelectedValue()" (selectionChange)="onSelect($event.value)">
      <mat-select-trigger *ngIf="!currentDomain">All domains</mat-select-trigger>
      <mat-option *ngFor="let domain of domains" [value]="''+domain.id">
        <mat-icon>{{ getIconName(domain) }}</mat-icon>
        {{ domain.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
