<ng-container *ngIf="visible">
  <mat-form-field *ngIf="langs?.length" [ngClass]="cssClassName">
    <mat-label *ngIf="label?.length">{{ label }}</mat-label>
    <mat-select #select class="lang-dropdown"
                [value]="getSelectedValue()"
                (selectionChange)="onSelect($event.value)"
                [multiple]="multipleSelect">
      <div *ngIf="multipleSelect" class="ctrl-select select-all">
        <a mat-button [disabled]="allSelected" (click)="!allSelected && toggleAllSelection()">
          <mat-icon fontIcon="check"></mat-icon>
          Select All</a>
      </div>
      <div *ngIf="multipleSelect" class="ctrl-select select-none">
        <a mat-button [disabled]="singleSelected" (click)="!singleSelected && toggleAllSelection(false)">
          <mat-icon fontIcon="clear"></mat-icon>
          None</a>
      </div>
      <mat-select-trigger *ngIf="allSelected">{{ allSelectedLabel }}</mat-select-trigger>
      <mat-option value='' class="d-none">{{ allSelectedLabel }}</mat-option>
      <mat-option *ngFor="let lang of langs" [value]="lang.code"
                  [disabled]="isDisabled(lang)">{{ lang.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
