import {NgModule} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MAT_SELECT_CONFIG, MatSelectConfig, MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [],
  imports: [
    MatFormFieldModule, MatSnackBarModule, MatSelectModule, MatInputModule, MatButtonModule, MatListModule,
    MatCardModule, MatSlideToggleModule, MatExpansionModule, MatIconModule, MatTooltipModule, MatTabsModule,
    MatTableModule, MatProgressSpinnerModule, MatDialogModule, MatToolbarModule, MatMenuModule, MatDividerModule,
    MatPaginatorModule, MatButtonToggleModule, MatGridListModule, MatSidenavModule, MatCheckboxModule
  ],
  providers: [{
    provide: MAT_SELECT_CONFIG,
    useValue: {
      panelWidth: null
    } as MatSelectConfig,
  }],
  exports: [MatFormFieldModule, MatSnackBarModule, MatSelectModule, MatInputModule, MatButtonModule, MatListModule,
    MatCardModule, MatSlideToggleModule, MatExpansionModule, MatIconModule, MatTooltipModule, MatTabsModule,
    MatTableModule, MatProgressSpinnerModule, MatDialogModule, MatToolbarModule, MatMenuModule, MatDividerModule,
    MatPaginatorModule, MatButtonToggleModule, MatGridListModule, MatSidenavModule, MatCheckboxModule]
})
export class MaterialModule { }
