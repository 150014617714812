import {Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ResultViewDirective} from './result-view.directive';
import {ConceptService, DictionaryService} from '@twpub/core/services';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {ConceptSelection, DisplayConcept, Language, ResultView} from '@twpub/core/models';
import {BaseWrapperComponent} from '@twpub/shared/components/base';
import {hasPropChanges} from '@twpub/core/utils';

const {DICT_ID, TARGET_LANGS, CONCEPT_ID, TERM_ID} = SessionParam;

@Component({
  selector: 'pub-result-view-wrapper',
  template: '<ng-template pubResultView></ng-template>'
})
/**
 * Wrapper component for result view.
 */
export class ResultViewWrapperComponent extends BaseWrapperComponent<ResultView> implements OnChanges {
  override compCategory = ComponentCategory.ResultView;
  override changesTriggers: SessionParam[] = [CONCEPT_ID, TERM_ID, DICT_ID, TARGET_LANGS];

  private languages: Language[] = [];
  @Input() conceptId?: number; // Overrides session conceptId
  @Input() termId?: number; // Overrides session termId

  @Output() conceptSelected = new EventEmitter<ConceptSelection>();

  @ViewChild(ResultViewDirective, {static: true}) pubResultView!: ResultViewDirective

  concept?: DisplayConcept;

  constructor(injector: Injector, private conceptService: ConceptService, private langService: DictionaryService) {
    super(injector);
  }

  override createComponent(): ResultView {
    const instance = super.createComponent();
    instance.conceptSelected.subscribe((selection) => {
      this.conceptSelected.emit(selection)
    })
    this.update();
    return instance;
  }

  override init(): void {
    super.init();
    this.sharedStateService.conceptSelection$.subscribe(() => this.getConcept());
    this.getConcept();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (hasPropChanges(changes, ['conceptId', 'termId'])) {
      this.getConcept();
    }
  }

  protected override handleSessionChanges() {
    this.logger.debug('ResultViewWrapperComponent.handleSessionChanges:', {})
    this.getConcept();
  }

  private getConcept() {
    const cId = this.getConceptId();
    if (cId) {
      this.conceptService.getConcept(cId).subscribe({
        next: (result) => {
          this.concept = result;
          this.fetchLanguages(result.dictId);
        }
      });
    } else {
      if (this.concept) {
        this.concept = undefined;
        this.update();
      }
    }
  }

  /**
   * Returns the concept id from input params if set, otherwise concept id from session.
   */
  private getConceptId() {
    return this.conceptId ? this.conceptId : this.sharedStateService.getConceptId();
  }

  /**
   * Returns the term id from input params if concept id is set in input param, otherwise term id from session.
   */
  private getTermId() {
    return this.conceptId ? this.termId : this.sharedStateService.getTermId();
  }

  private fetchLanguages(dictId: number) {
    this.langService.getLanguages(dictId).subscribe({
      next: (langs) => {
        const sourceLangs = this.sharedStateService.getSourceLangs();
        const targetLangs = this.sharedStateService.getTargetLangs();
        this.languages = langs.filter((l) => !sourceLangs.length || sourceLangs.includes(l.code) || !targetLangs.length || targetLangs.includes(l.code));
        this.update();
      }
    })
  }

  override update() {
    const existsForId = this.conceptExistsForId();
    if (!existsForId) return;
    super.update((comp) => {
      comp.concept = this.concept;
      comp.languages = this.languages;
      comp.termId = this.getTermId();
    });
  }

  private conceptExistsForId() {
    return !(this.getConceptId() && !this.concept);
  }

  getViewContainerRef() {
    return this.pubResultView.viewContainerRef;
  }
}
