import {Component, Injector} from '@angular/core';
import {DictionaryService} from '@twpub/core/services';
import {Language, LanguageSelector} from '@twpub/core/models';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  template: ''
})
export abstract class BaseLanguageWrapperComponent extends BaseWrapperComponent<LanguageSelector> {
  languages?: Language[]
  protected constructor(protected injector: Injector, protected dictService: DictionaryService) {
    super(injector);
  }

  protected override handleSessionChanges() {
    this.fetchLanguages();
  }

  private fetchLanguages() {
    const dictId = this.sharedStateService.getDictionaryId();
    if (dictId) {
      // const locale = this.sessionObj?.locale;
      this.dictService.getLanguages(dictId).subscribe({
        next: (languages) => {
          this.languages = languages;
          this.update();
        }
      })
    }
  }

  override createComponent(): LanguageSelector {
    const comp = super.createComponent();
    comp.selectLanguages.subscribe((langCodes: string[]) => this.selectLanguages(langCodes));
    this.fetchLanguages();
    return comp;
  }

  protected abstract selectLanguages(langCodes: string[]): void;

  protected abstract getCurrentLanguages(): string[];

  override update(updateFn?: (comp: LanguageSelector) => void): void {
    super.update((comp) => {
      if (updateFn) {
        updateFn(comp);
      } else {
        comp.langs = this.languages
        comp.currentLanguages = this.getCurrentLanguages();
      }
    });
  }
}
