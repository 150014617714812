import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {BaseWrapperComponent} from '@twpub/shared/components/base';
import {AttachmentRender} from '@twpub/core/models/wrappers/attachment-render';
import {ComponentCategory} from '@twpub/core/enums';
import {AttachmentRenderDirective} from '@twpub/shared/components/wrappers';
import {VirtualFile} from '@twpub/core/models';
import {VirtualFileService} from '@twpub/core/services/virtual-file.service';
import {hasPropChanges} from '@twpub/core/utils';

@Component({
  selector: 'pub-attachment-render-wrapper',
  template: '<ng-template pubAttachmentRender></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentRenderWrapperComponent extends BaseWrapperComponent<AttachmentRender> implements OnChanges {
  override compCategory = ComponentCategory.AttachmentRender;

  @Input() fileId!: number;
  vfile?: VirtualFile;
  thumbUrl?: string;
  contentUrl?: string;
  @ViewChild(forwardRef(() => AttachmentRenderDirective), {static: true}) pubAttachmentRender!: AttachmentRenderDirective
  constructor(private injector: Injector, private fileService: VirtualFileService, private changeRef: ChangeDetectorRef) {
    super(injector);
  }

  protected override handleSessionChanges() {
    this.logger.debug('AttachmentRenderWrapperComponent.handleSessionChanges:', {fileId: this.fileId})
    this.loadFile()
  }

  private loadFile() {
    if (this.fileId) {
      this.fileService.getVirtualFile(this.fileId).subscribe({
        next: (file) => {
          this.vfile = file;
          this.thumbUrl = this.fileService.getContentUrl(this.fileId, true);
          this.contentUrl = this.fileService.getContentUrl(this.fileId);
          this.update();
        }
      })
    }
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (hasPropChanges(changes, ['fileId'])) {
      this.loadFile();
    }
  }

  getViewContainerRef() {
    return this.pubAttachmentRender.viewContainerRef;
  }

  override update() {
    super.update((comp) => {
      comp.vfile = this.vfile;
      comp.thumbUrl = this.thumbUrl;
      comp.contentUrl = this.contentUrl;
      // Need explicit call to detect changes since this is used within wrapper component and value is not updated from wrapper
      this.changeRef.detectChanges();
      this.logger.debug('AttachmentRenderWrapperComponent.updated child:', {file: this.vfile?.id})
    })
  }
}
