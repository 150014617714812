import {Component, forwardRef, Injector, ViewChild} from '@angular/core';
import {SectionSelector} from '@twpub/core/models/wrappers/section-selector';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {SectionSelectorDirective} from './section-selector.directive';
import {Section} from '@twpub/core/models';
import {DictionaryService} from '@twpub/core/services';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-section-wrapper',
  template: '<ng-template pubSectionSelect></ng-template>'
})
export class SectionWrapperComponent extends BaseWrapperComponent<SectionSelector> {
  override compCategory = ComponentCategory.SectionSelector;
  override changesTriggers: SessionParam[] = [SessionParam.DICT_ID];
  sections: Section[] = [];
  @ViewChild(forwardRef(() => SectionSelectorDirective), {static: true}) pubSectionSelect!: SectionSelectorDirective

  constructor(private injector: Injector, private dictService: DictionaryService) {
    super(injector)
  }

  protected override handleSessionChanges() {
    this.fetchSections();
  }

  private fetchSections() {
    const dictId = this.sharedStateService.getDictionaryId();
    this.logger.debug('SectionWrapperComponent.fetchSections:', {dictId})
    if (dictId) {
      this.dictService.getSections(dictId).subscribe((sections) => {
        this.sections = sections;
        this.update();
      });
    }
  }

  getViewContainerRef() {
    return this.pubSectionSelect.viewContainerRef;
  }

  override createComponent(): SectionSelector {
    const comp = super.createComponent();
    comp.selectSections.subscribe((event: string[]) => this.sharedStateService.selectSections(event))
    this.fetchSections()
    return comp;
  }

  override update() {
    super.update((comp) => {
      comp.currentSections = this.sharedStateService.getSectionOids();
      comp.sections = this.sections;
    })
  }
}
