import {Component, Injector, ViewChild} from '@angular/core';
import {ResultListDirective} from './result-list.directive';
import {ResultList, SessionObject} from '@twpub/core/models';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

const {DICT_ID, DOMAIN_IDS} = SessionParam;

const {SECTION_OIDS, SEARCH_TERM, SOURCE_LANGS, TARGET_LANGS} = SessionParam;

@Component({
  selector: 'pub-result-list-wrapper',
  template: '<ng-template pubResultList></ng-template>'
})
export class ResultListWrapperComponent extends BaseWrapperComponent<ResultList> {
  override compCategory = ComponentCategory.ResultList;
  override changesTriggers: SessionParam[] = [DICT_ID, DOMAIN_IDS, SEARCH_TERM, SECTION_OIDS, SOURCE_LANGS, TARGET_LANGS];
  @ViewChild(ResultListDirective, {static: true}) pubResultList!: ResultListDirective

  constructor(private injector: Injector) {
    super(injector);
  }

  override createComponent(): ResultList {
    const instance = super.createComponent();
    super.update();
    return instance;
  }

  protected override handleSessionChanges(session: Partial<SessionObject>) {
    super.update(undefined, session);
  }

  getViewContainerRef() {
    return this.pubResultList.viewContainerRef;
  }
}
