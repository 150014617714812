import {
  Component,
  ComponentRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ClientConfiguration, ComponentConfiguration} from '@twpub/core/utils';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {ConfigurableComponent, SessionObject} from '@twpub/core/models';
import {ClientConfigurationService, ComponentDeclarationService, SharedStateService} from '@twpub/core/services';
import {ActivatedRoute} from '@angular/router';
import {distinctUntilChanged, filter, map, Subscription, tap} from 'rxjs';

@Component({
  template: ''
})

export abstract class BaseWrapperComponent<T extends ConfigurableComponent> implements OnInit, OnChanges, OnDestroy {
  protected abstract compCategory: ComponentCategory
  protected changesTriggers: SessionParam[] = []
  protected logger: NGXLogger
  protected clientConfigService: ClientConfigurationService
  protected sharedStateService: SharedStateService;
  protected componentConfig?: ComponentConfiguration<T>
  protected componentRef?: ComponentRef<T>
  private componentDeclarationService: ComponentDeclarationService;
  private route: ActivatedRoute;

  visible: boolean = true
  protected subscriptions = new Subscription();

  @Input() configId?: number; // Overrides route data clientConfiguration

  private clientConfig!: ClientConfiguration

  constructor(private injectorObj: Injector) {
    this.logger = injectorObj.get(NGXLogger);
    this.componentDeclarationService = injectorObj.get(ComponentDeclarationService);
    this.sharedStateService = injectorObj.get(SharedStateService);
    this.clientConfigService = injectorObj.get(ClientConfigurationService);
    this.route = injectorObj.get(ActivatedRoute);
  }

  filterOutTriggerParams = (session: SessionObject) =>
    Object.fromEntries(
      this.changesTriggers
        .filter(param => session?.hasOwnProperty(param))
        .map(trigger => [trigger, session[trigger]])
    ) as Partial<SessionObject>;

  createPartialWithChanges = (prev: Partial<SessionObject>, curr: Partial<SessionObject>) => {
    const changedProps = Object.fromEntries(
      this.changesTriggers
        .filter(param => prev?.[param] !== curr?.[param])
        .map(param => [param, curr[param]])
    ) as Partial<SessionObject>;
    if (Object.keys(changedProps).length > 0) {
      (curr as any).__changedProps = changedProps;
      return false;
    }
    return true;
  };

  ngOnInit(): void {
    this.route.data.subscribe({
      next: (data) => {
        this.clientConfig = data['clientConfig']
        this.logger.debug('BaseWrapperComponent (' + this.constructor.name + ') init from route data:', {config: this.clientConfig});
        this.loadComponent();
        this.init();
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('configId')) {
      this.logger.debug('BaseWrapperComponent.ngOnChanges:', {configId: changes['configId'].currentValue})
      this.configId = changes['configId'].currentValue;
      this.clientConfigService.getDefaultConfiguration(this.configId).subscribe((config) => {
        this.clientConfig = config;
        this.logger.debug('BaseWrapperComponent (' + this.constructor.name + ') init from configId:', {config});
        this.loadComponent();
        this.init();
      });
    }
  }

  protected init(): void {
    if (this.changesTriggers.length === 0) {
      this.update();
      return;
    }
    const compName = 'BaseWrapperComponent (' + this.constructor.name + ')';
    this.subscriptions.add(
      this.sharedStateService.session$
        .pipe(
          map(this.filterOutTriggerParams),
          distinctUntilChanged(this.createPartialWithChanges),
          map(curr => (curr as any).__changedProps),
          tap((session) => this.logger.debug(compName + ' found distinct: ' + JSON.stringify(session))),
          filter((session) => this.changesTriggers.some((param) => session?.hasOwnProperty(param)))
        ).subscribe((session: Partial<SessionObject>) => {
        this.handleSessionChanges(session);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadComponent() {
    this.getViewContainerRef().clear()
    if (this.clientConfig) {
      this.createComponent();
    } else {
      throw new Error('No client configuration for ' + this.constructor.name);
    }
  }

  protected createComponent(): T {
    this.componentConfig = this.getConfiguration(this.compCategory);
    this.componentRef = this.getViewContainerRef().createComponent<T>(this.componentConfig.component);
    return this.componentRef.instance;
  }

  protected getConfiguration(compCategory: ComponentCategory): ComponentConfiguration<any> {
    let config = this.clientConfig.getConfigurationSafe(compCategory);
    if (!config) {
      config = this.componentDeclarationService.createComponentConfigurationForCategory(compCategory, this.clientConfig.scheme);
      if (!config) {
        throw new Error('No component for type ' + compCategory + ' in scheme ' + this.clientConfig.scheme);
      }
    }
    return config;
  }

  protected abstract getViewContainerRef(): ViewContainerRef

  update(updateFn?: (comp: T) => void, updateArgs?: any) {
    if (this.componentRef) {
      const comp = this.componentRef.instance;
      this.logger.debug('BaseWrapperComponent (' + this.constructor.name + ') updates component')
      comp.logger = this.logger;
      comp.config = this.componentConfig;
      comp.compCategory = this.compCategory;
      if (updateFn) {
        updateFn(comp);
      }
      comp.doUpdate?.(updateArgs);
    } else {
      this.logger.debug('BaseWrapperComponent (' + this.constructor.name + ') No componentRef')
    }
  }

  protected handleSessionChanges(session: Partial<SessionObject>): void {
    this.update();
  }
}
