import {Component, Injector, ViewChild} from '@angular/core';
import {DictionarySelectorDirective} from './dictionary-selector.directive';
import {Dictionary, DictionarySelector, SessionObject} from '@twpub/core/models';
import {DictionaryService} from '@twpub/core/services';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-dictionary-wrapper',
  template: '<ng-template pubDictSelect></ng-template>'
})
export class DictionaryWrapperComponent extends BaseWrapperComponent<DictionarySelector> {
  override compCategory = ComponentCategory.DictionarySelector;
  override changesTriggers: SessionParam[] = [];
  dictionaries?: Dictionary[];

  @ViewChild(DictionarySelectorDirective, {static: true}) pubDictSelect!: DictionarySelectorDirective

  constructor(private injector: Injector, private dictService: DictionaryService) {
    super(injector);
  }

  override init(): void {
    this.subscriptions.add(
      this.sharedStateService.subscribe(() => {
        this.dictService.getDictionaries(true).subscribe({
          next: (dicts) => {
            this.dictionaries = dicts;
            this.update();
          }
        })
      })
    )
  }

  protected override handleSessionChanges(session: Partial<SessionObject>) {
    this.update();
  }

  getViewContainerRef() {
    return this.pubDictSelect.viewContainerRef;
  }

  override createComponent(): DictionarySelector {
    const comp = super.createComponent();
    comp.selectDictionary.subscribe((dictId: number) => {
      this.logger.debug('DictionaryWrapperComponent.selectDict:' + dictId);
      this.sharedStateService.selectDictionary(dictId);
    });
    super.update();
    return comp;
  }

  override update() {
    super.update((comp) => {
      comp.currentDictionary = this.sharedStateService.getDictionaryId();
      comp.dictionaries = this.dictionaries
    })
  }
}
